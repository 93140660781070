import React, { useState } from "react"
import { Link } from "gatsby"
import ContentBlock from "../ContentBlock"
import { Waypoint } from "react-waypoint"
import { useSpring, animated } from "react-spring"

const TrialBanner = () => {
  const [isVisible, toggle] = useState(false)
  const trialButtonSpring = useSpring({
    to: {
      transform: isVisible ? "scale(1)" : "scale(0)",
    },
    config: {
      tension: 500,
      friction: 25,
    },
  })
  return (
    <ContentBlock containerClassName="mt-auto">
      <div className="items-end p-6 mt-8 bg-gray-900 rounded lg:rounded-lg lg:flex lg:-mx-8 lg:mt-16 lg:p-8">
        <div className="">
          <h2 className="text-2xl text-gray-100">
            Sign up for a free 30-day trial!
          </h2>
          <p className="mt-3 text-gray-300">
            Experience the best of digital dispatching, mobile applications,
            sophisticated billing, and our comprehensive reporting, all with no
            contract and no setup fees.
          </p>
        </div>
        <div className="mt-6 lg:mt-0 lg:ml-8">
          <animated.div style={trialButtonSpring}>
            <Link
              to="/signup/"
              className="flex-shrink-0 block w-full px-4 py-2 text-center text-gray-900 whitespace-no-wrap bg-yellow-500 rounded lg:w-auto lg:px-5 lg:py-3 lg:text-lg hover:bg-yellow-400 font-400"
            >
              Get Started Now!
            </Link>
          </animated.div>
        </div>
      </div>
      <Waypoint
        onEnter={() => {
          if (!isVisible) toggle(true)
        }}
      />
    </ContentBlock>
  )
}

export default TrialBanner
