import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Header from "../Header"
import Footer from "../Footer"
import TrialBanner from "../TrialBanner"
import { Waypoint } from "react-waypoint"
import { getParam } from "../../utils"

const Layout = ({ showTrialBanner = true, children }) => {
  const [headerBg, setHeaderBg] = useState("bg-gray-800")

  useEffect(() => {
    if (
      document.referrer &&
      !document.referrer.includes(window.location.host)
    ) {
      window.localStorage.setItem("referer", document.referrer)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      let gclid = getParam("gclid")
      if (gclid) window.localStorage.setItem("gclid", gclid)

      let utm_source = getParam("utm_source")
      if (utm_source) window.localStorage.setItem("utm_source", utm_source)

      let utm_medium = getParam("utm_medium")
      if (utm_medium) window.localStorage.setItem("utm_medium", utm_medium)

      let utm_campaign = getParam("utm_campaign")
      if (utm_campaign)
        window.localStorage.setItem("utm_campaign", utm_campaign)

      let utm_term = getParam("utm_term")
      if (utm_term) window.localStorage.setItem("utm_term", utm_term)

      let utm_content = getParam("utm_content")
      if (utm_content) window.localStorage.setItem("utm_content", utm_content)
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{`Towbook Towing Software - Cloud based towing software for dispatching, impounds, & accounting`}</title>
        <meta
          name="description"
          content="Towbook provides cloud based towing software for dispatching, impounds and reporting. iPhone and Android apps available. Free 30-day towing software trial available."
        />
        <meta
          name="keywords"
          content="towing software, towing management software, web based towing software, impound management software, storage lot software, private property impound software"
        />
        <meta property="fb:page_id" content="73837129791" />
        <meta property="fb:admins" content="527370189" />
        <meta property="og:title" content="Towbook Management Software" />
        <meta property="og:type" content="product" />
        <meta property="og:site_name" content="Towbook" />
        <meta property="og:url" content="https://towbook.com/" />
        <meta property="og:phone_number" content="810-320-5063" />
        <meta
          property="og:description"
          content="Towbook Towing Software is an easy to use web-based management software product for towing companies of all sizes. Dispatching, Impounds, Accounting and more, all accessible from any device with internet access."
        />
        <html lang="en" />
        <body className="font-sans text-gray-100 bg-gray-800 font-400" />
      </Helmet>
      <div className="flex flex-col min-h-screen">
        <Header className={headerBg}></Header>
        <Waypoint
          onEnter={() => {
            setHeaderBg("bg-gray-800")
          }}
          onLeave={() => {
            setHeaderBg("bg-gray-900")
          }}
        />
        {children}
        <div className="mt-auto">
          {showTrialBanner && <TrialBanner></TrialBanner>}
          <Footer></Footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  showTrialBanner: PropTypes.bool,
  children: PropTypes.node,
}

export default Layout
