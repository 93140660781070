import React from "react"
import PropTypes from "prop-types"

const ContentBlock = ({
  condition = true,
  wrap = children => (
    <ContentBlockContainer
      containerClassName={containerClassName}
      className={className}
    >
      {children}
    </ContentBlockContainer>
  ),
  children,
  className,
  containerClassName,
}) => (condition ? wrap(children) : <>{children}</>)

const ContentBlockContainer = ({
  containerClassName = "",
  className = "",
  children,
}) => (
  <section className={containerClassName}>
    <div className={`w-full max-w-6xl mx-auto px-6 lg:px-8 ${className}`}>
      {children}
    </div>
  </section>
)

ContentBlock.propTypes = {
  condition: PropTypes.bool,
  wrap: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
}

ContentBlockContainer.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default ContentBlock
