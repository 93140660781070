import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "../Logo"
import { ReactComponent as IconNavigationMenu } from "../../icons/navigation-menu.svg"
import { ReactComponent as IconClose } from "../../icons/close.svg"
import { navigation } from "../../data/navigation"
import ContentBlock from "../ContentBlock"

const Header = ({ className = "" }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <>
      <ContentBlock
        containerClassName={`
          fixed
          top-0
          left-0
          right-0
          z-50
          ${
            menuIsOpen
              ? "fixed lg:static top-0 left-0 right-0 bottom-0 bg-gray-900 lg:bg-transparent"
              : ""
          }
          ${className}`}
        className="h-full"
      >
        <header
          className={`
          flex flex-col lg:flex-row
          mx-auto lg:pt-6 lg:py-6
          text-gray-100
          pt-6 lg:pt-0
          h-full
        `}
        >
          <div className="flex items-center flex-shrink-0 mb-6 lg:mb-0">
            <h1>
              <Link to="/">
                <Logo className="h-8 text-white lg:h-10"></Logo>
              </Link>
            </h1>
            <button
              className="px-3 py-2 ml-auto -mr-3 rounded lg:hidden"
              aria-label="Toggle menu"
              onClick={() => setMenuIsOpen(!menuIsOpen)}
            >
              {!menuIsOpen ? (
                <IconNavigationMenu className="w-5 h-5" />
              ) : (
                <IconClose className="w-5 h-5" />
              )}
            </button>
          </div>

          <nav
            className={`
            flex flex-col lg:flex-row lg:items-center flex-1 lg:flex-initial -mx-3 lg:mx-0 lg:ml-auto pb-3 lg:p-0 min-h-0 font-400
            ${menuIsOpen ? "" : "hidden lg:flex"}
          `}
          >
            <ul className="overflow-auto lg:flex lg:flex-initial lg:overflow-visible lg:m-0">
              {navigation.header.map(({ name, route }, index) => (
                <li key={index} className="mb-2 lg:m-0 lg:ml-3">
                  {route.includes("http") ? (
                    <a
                      href={route}
                      className="block px-4 py-2 rounded hover:bg-gray-800 lg:hover:bg-gray-700"
                    >
                      {name}
                    </a>
                  ) : (
                    <Link
                      to={route}
                      className="block px-4 py-2 rounded hover:bg-gray-800 lg:hover:bg-gray-700"
                      activeClassName="text-gray-800 bg-gray-100 pointer-events-none"
                    >
                      {name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            <div className="flex flex-shrink-0 mt-1 text-center lg:m-0 lg:ml-3">
              <Link
                to="/signup/"
                className="flex-1 block px-4 py-2 text-gray-800 bg-yellow-500 rounded lg:flex-initial hover:bg-yellow-400"
              >
                Free Trial
              </Link>
              <a
                href="https://app.towbook.com/Security/Login.aspx"
                target="_parent"
                className={`
                block flex-1 lg:flex-initial
                ml-3 
                rounded
                px-4 py-2
                bg-blue-500 hover:bg-blue-400
                text-white
                `}
              >
                Log In
              </a>
            </div>
          </nav>
        </header>
      </ContentBlock>
      {/* Spacer for fixed header */}
      <div className="py-6 lg:py-0">
        <div className="h-5 my-2 lg:my-6 lg:h-10"></div>
      </div>
    </>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
