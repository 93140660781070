import React from "react"
import { Link } from "gatsby"
import Logo from "../Logo"
import ContentBlock from "../ContentBlock"
import { navigation } from "../../data/navigation"

const Footer = () => {
  let year = new Date().getFullYear()
  return (
    <footer>
      <div className="mt-8 text-gray-300 bg-gray-900 lg:mt-16">
        <ContentBlock className="flex flex-col py-6 lg:py-16 lg:flex-row">
          <div className="flex flex-col order-2 mt-6 lg:order-none lg:mt-0">
            <h1>
              <Link to="/">
                <Logo className="h-8 text-white lg:h-10"></Logo>
              </Link>
            </h1>
            <div className="mt-2 mb-6 text-sm lg:text-base">
              Copyright {year} - Towbook / Extric LLC
            </div>
            <div className="flex mt-auto">
              {navigation.social.map(({ name, route, icon: Icon }, index) => (
                <a
                  key={index}
                  href={route}
                  title={name}
                  target="_blank"
                  rel="noopener noreferrer me"
                  className="block p-3 mr-4 text-gray-100 bg-gray-800 rounded-full hover:bg-gray-700"
                >
                  <Icon className="w-4 h-4 lg:w-6 lg:h-6" />
                </a>
              ))}
            </div>
          </div>
          <nav className="flex text-sm lg:ml-auto lg:text-lg">
            <ul className="flex-1 lg:flex-initial">
              {navigation.footer.map(({ name, route }, index) => (
                <li key={index} className={index !== 0 ? "mt-1" : ""}>
                  {route.includes("http") ? (
                    <a href={route} className="hover:text-white">
                      {name}
                    </a>
                  ) : (
                    <Link
                      to={route}
                      className="hover:text-white"
                      activeClassName="text-white"
                    >
                      {name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            <ul className="flex-1 lg:flex-initial lg:ml-24">
              {navigation.secondary.map(({ name, route }, index) => (
                <li key={index} className={index !== 0 ? "mt-1" : ""}>
                  {route.includes("http") ? (
                    <a href={route} className="hover:text-white">
                      {name}
                    </a>
                  ) : (
                    <Link
                      to={route}
                      className="hover:text-white"
                      activeClassName="text-white"
                    >
                      {name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </ContentBlock>
      </div>
    </footer>
  )
}

export default Footer
