import { ReactComponent as IconFacebook } from "../icons/facebook.svg"
import { ReactComponent as IconInstagram } from "../icons/instagram.svg"
import { ReactComponent as IconTwitter } from "../icons/twitter.svg"
import { ReactComponent as IconYouTube } from "../icons/youtube.svg"

export const navigation = {
  header: [
    {
      name: "Features",
      route: "/features/",
    },
    {
      name: "Pricing",
      route: "/pricing/",
    },
    {
      name: "Support",
      route: "/support/",
    },
    {
      name: "Contact",
      route: "/contact/",
    },
  ],
  footer: [
    {
      name: "Features",
      route: "/features/",
    },
    {
      name: "Pricing",
      route: "/pricing/",
    },
    {
      name: "Support",
      route: "/support/",
    },
    {
      name: "Contact",
      route: "/contact/",
    },
    {
      name: "Blog",
      route: "http://blog.towbook.com",
    },
  ],
  secondary: [
    {
      name: "Knowledge Base",
      route: "http://support.towbook.com",
    },
    {
      name: "Refund Policy",
      route: "/refund-policy/",
    },
    {
      name: "Terms of Service",
      route: "/terms-of-service/",
    },
    {
      name: "Privacy Policy",
      route: "/privacy-policy/",
    },
    {
      name: "Data Request Policy",
      route: "/data-request-policy/",
    },
    {
      name: "What Towbook is Not",
      route: "/what-towbook-is-not/",
    },
  ],
  social: [
    {
      name: "Facebook",
      route: "https://www.facebook.com/Towbook",
      icon: IconFacebook,
    },
    {
      name: "Instagram",
      route: "https://www.instagram.com/towbook_official",
      icon: IconInstagram,
    },
    {
      name: "Twitter",
      route: "https://twitter.com/towbook",
      icon: IconTwitter,
    },
    {
      name: "YouTube",
      route: "https://www.youtube.com/channel/UCeNSIDzSDjwKr1CA3rmqlUQ",
      icon: IconYouTube,
    },
  ],
}
